import styled from 'styled-components';
import { device } from '../styles/globals';
import Mind from '../svgs/mind.svg';
import Heart from '../svgs/heart.svg';
import Duo from '../svgs/duo.svg';
import Access from '../svgs/access.svg';
import AI from '../svgs/ai.svg';
import Care from '../svgs/care.svg';
import Band from '../svgs/band.svg';
import Apps from '../svgs/apps.svg';
import Patients from '../svgs/patients.svg';
import Providers from '../svgs/providers.svg';
import Payer from '../svgs/payer.svg';
import ICM from '../svgs/ICM.svg';
import ICMBlack from '../svgs/ICM-black.svg';
import Spectrum from '../svgs/spectrum.svg';
import AllSymbol from '../svgs/all-symbol-full.svg';
import HandHeart from '../svgs/hand-heart.svg';
import Solution from '../svgs/solution.svg';
import PopulationH from '../svgs/population-h.svg';
import PopulationV from '../svgs/population-v.svg';
import ClinicalScience from '../svgs/clinical-science.svg';
import OHRP from '../svgs/OHRP.svg';
import FDA from '../svgs/fda.svg';
import Bassett from '../svgs/bassett.svg';
import Froedtert from '../svgs/froedtert.svg';
import DOHC from '../svgs/dohc.svg';
import UCSF from '../svgs/ucsf.svg';
import Stanford from '../svgs/stanford.svg';
import Harvard from '../svgs/harvard.svg';
import ASU from '../svgs/asu.svg';
import Mayo from '../svgs/mayo.svg';

const iconStyles = `
  @media ${device.tablet} {
    height: 180px;
    width: 180px;
  }

  @media ${device.mobileL} {
    height: 120px;
    width: 120px;
  }
`;

const bigIconStyles = `
  @media ${device.tablet} {
    height: 360px;
    width: 360px;
  }

  @media ${device.mobileL} {
    height: 240px;
    width: 240px;
  }
`;

const teamIconStyles = `
  @media ${device.tablet} {
    height: 240px;
    width: 300px;
  }

  @media ${device.mobileL} {
    height: 160px;
    width: 200px;
  }
`;

const ICMIconStyles = `
  @media ${device.laptopL} {
    height: 396px;
    width: 380px;
  }

  @media ${device.tablet} {
    height: 286px;
    width: 274px;
  }

  @media ${device.mobileL} {
    height: 225px;
    width: 216px;
  }
`;

const partnerIconStyles = `
  height: 60px;
  width: fit-content;

  @media ${device.laptopM} {
    height: 50px;
  }

  @media ${device.mobileL} {
    height: 40px;
  }
`;

export const SpectrumIcon = styled(Spectrum)`
  @media ${device.laptopL} {
    height: 358px;
    width: 380px;
  }

  @media ${device.tablet} {
    height: 286px;
    width: 304px;
  }

  @media ${device.mobileL} {
    height: 190px;
    width: 202px;
  }
`;

export const AllSymbolIcon = styled(AllSymbol)`
  @media ${device.laptopL} {
    height: 275px;
    width: 380px;
  }

  @media ${device.mobileL} {
    height: 160px;
    width: 225px;
  }
`;

export const HandHeartIcon = styled(HandHeart)`
  @media ${device.tablet} {
    height: 260px;
    width: 260px;
  }

  @media ${device.mobileL} {
    height: 150px;
    width: 150px;
  }
`;

export const SolutionIcon = styled(Solution)`
  width: 100%;
`;

export const PopulationHIcon = styled(PopulationH)`
  width: 100%;

  @media ${device.tablet} {
    display: none;
  }
`;

export const PopulationVIcon = styled(PopulationV)`
  width: 100%;
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;

export const ClinicalScienceIcon = styled(ClinicalScience)`
  width: 100%;
  height: auto;
`;

export const OHRPIcon = styled(OHRP)`
  @media ${device.tablet} {
    width: 100%;
    height: 100%;
  }
`;

export const FDAIcon = styled(FDA)`
  @media ${device.tablet} {
    width: 100%;
    height: 100%;
  }
`;

export const MindIcon = styled(Mind)`${iconStyles}`;
export const HeartIcon = styled(Heart)`${iconStyles}`;
export const DuoIcon = styled(Duo)`${iconStyles}`;
export const AccessIcon = styled(Access)`${iconStyles}`;
export const AIIcon = styled(AI)`${iconStyles}`;
export const CareIcon = styled(Care)`${iconStyles}`;
export const PayersIcon = styled(Payer)`${iconStyles}`;
export const BandIcon = styled(Band)`${bigIconStyles}`;
export const AppsIcon = styled(Apps)`${bigIconStyles}`;
export const ICMIcon = styled(ICM)`${ICMIconStyles}`;
export const ICMBlackIcon = styled(ICMBlack)`${ICMIconStyles}`;
export const PatientsIcon = styled(Patients)`${teamIconStyles}`;
export const ProvidersIcon = styled(Providers)`${teamIconStyles}`;
export const BassettIcon = styled(Bassett)`${partnerIconStyles}`;
export const FroedtertIcon = styled(Froedtert)`${partnerIconStyles}`;
export const DOHCIcon = styled(DOHC)`${partnerIconStyles}`;
export const UCSFIcon = styled(UCSF)`${partnerIconStyles}`;
export const StanfordIcon = styled(Stanford)`${partnerIconStyles}`;
export const HarvardIcon = styled(Harvard)`${partnerIconStyles}`;
export const ASUIcon = styled(ASU)`${partnerIconStyles}`;
export const MayoIcon = styled(Mayo)`${partnerIconStyles}`;
