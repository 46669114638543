/* eslint-disable no-nested-ternary */

import styled from 'styled-components';
import { colors, device, styles } from '../styles/globals';

export const MainBanner = styled.section`
  width: 100%;
  height: 40vw;
  display: flex;
  background-color: ${props => props.backgroundColor || colors.blue};

  @media ${device.tabletM} {
    height: fit-content;
    flex-direction: ${props => props.reverse ? 'column-reverse' : 'column'};
  }
`;

export const MainBannerItem = styled.div`
  height: 100%;
  width: 50%;
  padding-left: ${props => props.isIcon ? '0' : props.left ? '15%' : props.wide ? '24px' : '40px'};
  padding-right: ${props => props.isIcon ? '0' : props.left ? props.wide ? '24px' : '40px' : '15%'};
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.isIcon ? 'flex-start' : 'center'};
  align-items: flex-start;

  @media ${device.desktopS} {
    padding-left: ${props => props.isIcon ? '0' : props.left ? '10%' : props.wide ? '24px' : '40px'};
    padding-right: ${props => props.isIcon ? '0' : props.left ? props.wide ? '24px' : '40px' : '10%'};
  }

  @media ${device.laptopL} {
    height: auto;
    padding: ${props => props.isIcon ? '0' : '50px 0'};
    padding-left: ${props => props.isIcon ? '0' : props.left ? '6%' : props.wide ? '24px' : '40px'};
    padding-right: ${props => props.isIcon ? '0' : props.left ? props.wide ? '24px' : '40px' : '6%'};
  }

  @media ${device.tablet} {
    width: ${props => props.wide && props.isIcon ? '100%' : '50%'};
  }

  @media ${device.tabletM} {
    width: 100%;
    padding: ${props => props.isIcon ? '0' : props.high ? '0 55px 72px' : '0 55px 48px'};
  }

  @media ${device.mobileL} {
    padding: ${props => props.isIcon ? '0' : props.high ? '0 35px 72px' : '0 35px 48px'};
  }
`;

export const MainBannerTitle = styled.h3`
  font-size: 48px;
  line-height: 1;
  font-family: Utopia Std;
  font-weight: 400;
  color: ${colors.white};

  @media ${device.laptopL} {
    font-size: 42px;
  }

  @media ${device.laptop} {
    font-size: 35px;
  }

  @media ${device.tabletM} {
    font-size: 29px;
  }
`;

export const Banner = styled.section`
  height: 40vw;
  width: 100%;
  display: flex;
  background-color: ${props => props.backgroundColor};

  @media ${device.laptopL} {
    height: ${props => props.short ? '40vw' : 'fit-content'};
    min-height: 570px;
  }

  @media ${device.tablet} {
    height: fit-content;
    min-height: auto;
    flex-direction: ${props => props.reverse ? 'column-reverse' : 'column'};
  }
`;

export const BannerItem = styled.div`
  height: 100%;
  width: 50%;
  padding-left: ${props => props.isIcon ? '0' : props.left ? '15%' : props.wide ? '24px' : '40px'};
  padding-right: ${props => props.isIcon ? '0' : props.left ? props.wide ? '24px' : '40px' : '15%'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => props.isIcon ? 'center' : 'flex-start'};

  @media ${device.desktopS} {
    padding-left: ${props => props.isIcon ? '0' : props.left ? '10%' : props.wide ? '24px' : '40px'};
    padding-right: ${props => props.isIcon ? '0' : props.left ? props.wide ? '24px' : '40px' : '10%'};
    padding-top: ${props => props.isIcon ? '60px' : '0'};
    padding-bottom: ${props => props.isIcon ? '60px' : '0'};
  }

  @media ${device.laptopL} {
    height: auto;
    padding: 50px 0;
    padding-left: ${props => props.isIcon ? '0' : props.left ? '6%' : props.wide ? '24px' : '40px'};
    padding-right: ${props => props.isIcon ? '0' : props.left ? props.wide ? '24px' : '40px' : '6%'};
  }

  @media ${device.tablet} {
    width: 100%;
    height: ${props => props.isIcon ? '360px' : 'auto'};
    padding: ${props => props.isIcon ? '0' : '0 55px 72px'};  
    align-items: ${props => props.isIcon || props.centered ? 'center' : 'flex-start'};
  }

  @media ${device.mobileL} {
    height: ${props => props.isIcon ? '300px' : 'auto'};
    padding: ${props => props.isIcon ? '0' : '0 35px 72px'};
  }
`;

export const BannerTitle = styled.h3`
  font-size: 48px;
  line-height: 1;
  font-family: Utopia Std;
  font-weight: 400;
  margin-bottom: ${props => props.single ? '0' : '21px'};
  color: ${(props) => props.color || styles.baseFontColor};

  @media ${device.tablet} {
    font-size: 38px;
    margin-bottom: ${props => props.single ? '0' : '18px'};
  }

  @media ${device.mobileL} {
    font-size: 29px;
    margin-bottom: ${props => props.single ? '0' : '14px'};
  }
`;

export const BannerDescription = styled.p`
  font-size: 21px;
  color: ${(props) => props.color || styles.baseFontColor};
  padding-left: ${(props) => props.isList ? '28px' : '0'};
  &:not(:last-child) {
    padding-bottom: 18px;
  }

  @media ${device.tablet} {
    font-size: 17px;
    &:not(:last-child) {
      padding-bottom: 16px;
    }
  }

  @media ${device.mobileL} {
    font-size: 14px;
    &:not(:last-child) {
      padding-bottom: 14px;
    }
  }
`;

export const BannerLi = styled.li`
  padding-bottom: 8px;
`;
