/* eslint-disable no-nested-ternary */

import styled from 'styled-components';
import { colors, device, styles } from '../styles/globals';

export const Section = styled.section`
  height: ${props => props.high ? '50vw' : '40vw'};
  width: 100%;
  display: flex;

  @media ${device.laptopL} {
    height: fit-content;
    min-height: 570px;
  }

  @media ${device.tablet} {
    min-height: auto;
    flex-direction: ${props => props.reverse ? 'column-reverse' : 'column'};
  }
`;

export const SectionItem = styled.div`
  height: 100%;
  width: 50%;
  background-color: ${props => props.backgroundColor || colors.white};
  padding-left: ${props => props.isIcon ? '0' : props.left ? '15%' : '10%'};
  padding-right: ${props => props.isIcon ? '0' : props.left ? '10%' : '15%'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => props.isIcon ? 'center' : 'flex-start'};

  @media ${device.desktopS} {
    padding-left: ${props => props.isIcon ? '0' : props.left ? '10%' : '5%'};
    padding-right: ${props => props.isIcon ? '0' : props.left ? '5%' : '10%'};
  }

  @media ${device.laptopL} {
    height: auto;
    padding: ${props => props.isIcon ? '0' : props.left ? '50px 4% 50px 6%' : '50px 6% 50px 4%'};
  }

  @media ${device.tablet} {
    width: 100%;
    height: ${props => props.isIcon ? '360px' : 'auto'};
    padding: ${props => props.isIcon ? '0' : '36px 55px'};  
  }

  @media ${device.mobileL} {
    height: ${props => props.isIcon ? props.bigIcon ? '300px' : '240px' : 'auto'};
    padding: ${props => props.isIcon ? '0' : '36px 35px'};
  }
`;

export const SectionTitle = styled.h3`
  font-size: 38px;
  line-height: 1;
  font-family: Utopia Std;
  font-weight: 400;
  margin-bottom: 18px;
  color: ${(props) => props.color || styles.baseFontColor};

  sub {
    font-size: 24px;
  }

  p {
    display: inline;
  }

  @media ${device.tablet} {
    font-size: 31px;
    margin-bottom: 16px;
  }

  @media ${device.mobileL} {
    font-size: 24px;
    margin-bottom: 14px;
  }
`;

export const SectionDescription = styled.p`
  font-size: 18px;
  font-family: Gotham SSm;
  padding-left: ${(props) => props.isList ? '24px' : '0'};
  &:not(:last-child) {
    padding-bottom: 18px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    &:not(:last-child) {
      padding-bottom: 16px;
    }
  }

  @media ${device.mobileL} {
    font-size: 14px;
    &:not(:last-child) {
      padding-bottom: 14px;
    }
  }
`;

export const SectionLi = styled.li`
  padding-bottom: 8px;
`;

export const LinkSection = styled.section`
  height: 40vw;
  width: 100%;
  display: flex;
  background-color: ${colors.grey1};

  @media ${device.laptopL} {
    height: fit-content;
    min-height: 570px;
  }

  @media ${device.tablet} {
    min-height: auto;
    justify-content: center;
  }
`;

export const LinkWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  column-gap: 48px;
  padding: 0 15%;

  @media ${device.desktopS} {
    padding: 0 10%;
  }

  @media ${device.laptopL} {
    height: auto;
    padding: 0 6%;
  }

  @media ${device.tablet} {
    flex-direction: column;
    padding: 72px 55px;
  }

  @media ${device.mobileL} {
    padding: 72px 35px;
  }
`;

export const LinkSectionItem = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media ${device.laptopL} {
    height: auto;
  }

  @media ${device.tablet} {
    width: auto;
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 72px;
    } 
  }
`;
